import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, TopImg, Title, Dot, Content, TitleGroup, Number, InfoTitle, InfoBlock, ImageBlock, Para} from "../styles/processStyles"
import MarineTopImg from "../images/marineTop.png"
import SpecsImg from "../images/specsImg.png"
import QuoteImg from "../images/quote.png"
import PaymentImg from "../images/payment.png"
import MaterialsImg from "../images/materials.png"
import BuildImg from "../images/build.png"
import ContactForm from "../components/contact"

export default class Process extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
      <Layout>
        <Seo 
          title="Our Process" 
          description="At Jakes on the Lakes we offer high quality services to make the dock you have envisioned while staying in your budget."
          keywords={["Jake On The Lakes", "Our Process", "SPECS + PLANNING", "DOCK QUOTE", "PAYMENT PROCESS", "MATERIALS", "DOCK BUILD",  "Northern Idaho", process.env.DRUPAL_WEBFORM_ENDPOINT, process.env.GATSBY_PHONE_NUMBER]}
        />
        <Top>
          <Title>OUR PROCESS</Title>
          <div><Dot/><Dot/><Dot/></div>
          <TopImg style={{backgroundImage: `url(${MarineTopImg})`}}></TopImg>
        </Top>
        <Content>
          <InfoBlock>
            <TitleGroup>
              <Number>1</Number>
              <InfoTitle>SPECS + PLANNING</InfoTitle>
            </TitleGroup>
            <Para>Once we receive a phone call or email from you, we will follow up to set a time to discuss your property, size and specific needs. Your dock size and shape needs are endless. Discussing your dock usage is the most important step to ensure you get the custom dock you need.</Para>
          </InfoBlock>
          <ImageBlock src={SpecsImg}/>
          <InfoBlock>
            <TitleGroup>
              <Number>2</Number>
              <InfoTitle>DOCK QUOTE</InfoTitle>
            </TitleGroup>
            <Para>Once we receive a phone call or email from you, we will follow up to set a time to discuss your property, size and specific needs. Your dock size and shape needs are endless. Discussing your dock usage is the most important step to ensure you get the custom dock you need.</Para>
          </InfoBlock>
          <ImageBlock src={QuoteImg}/>
          <InfoBlock>
            <TitleGroup>
              <Number>3</Number>
              <InfoTitle>PAYMENT PROCESS</InfoTitle>
            </TitleGroup>
            <Para>Once we receive a phone call or email from you, we will follow up to set a time to discuss your property, size and specific needs. Your dock size and shape needs are endless. Discussing your dock usage is the most important step to ensure you get the custom dock you need.</Para>
          </InfoBlock>
          <ImageBlock src={PaymentImg}/>
          <InfoBlock>
            <TitleGroup>
              <Number>4</Number>
              <InfoTitle>MATERIALS</InfoTitle>
            </TitleGroup>
            <Para>Once we receive a phone call or email from you, we will follow up to set a time to discuss your property, size and specific needs. Your dock size and shape needs are endless. Discussing your dock usage is the most important step to ensure you get the custom dock you need.</Para>
          </InfoBlock>
          <ImageBlock src={MaterialsImg}/>
          <InfoBlock>
            <TitleGroup>
              <Number>5</Number>
              <InfoTitle>DOCK BUILD</InfoTitle>
            </TitleGroup>
            <Para>Once we receive a phone call or email from you, we will follow up to set a time to discuss your property, size and specific needs. Your dock size and shape needs are endless. Discussing your dock usage is the most important step to ensure you get the custom dock you need.</Para>
          </InfoBlock>
          <ImageBlock src={BuildImg}/>
        </Content>
        <ContactForm data={this.props.data} />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`
